import {
    UPDATE_HEADER_VISIBILITY,
    SET_SEGMENT_NAME,
    RESET_SEGMENT_NAME,
} from '@/store/types/common'

export const updateHeaderVisibility = (payload) => ({
    type: UPDATE_HEADER_VISIBILITY,
    payload,
})

export const setSegmentName = (payload) => ({
    type: SET_SEGMENT_NAME,
    payload,
})
export const resetSegmentName = (payload) => ({
    type: RESET_SEGMENT_NAME,
    payload,
})
