import { ColumnFlex, RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const Container = styled.div`
    margin-bottom: 20px;
    ${ColumnFlex}
`
export const ItemContainer = styled.div`
    ${RowFlex}
    align-items: flex-start;
    padding: 0 24px;
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    gap: 20px;
`
export const CardContainer = styled.div`
    ${ColumnFlex}
    width: 140px;
    height: auto;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
    min-width: 0px;
`
export const CardImage = styled.div`
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    height: ${(props) => props.viewType === 'audiobook' && '372px'};
`

export const AudioBookCardImageContainer = styled.div`
    width: 100%;
    background-image: ${({ background }) => `url('${background}')`};
    background-size: cover;
    height: ${({ height }) => `${height}px`};
    border-radius: 8px;
    backdrop-filter: blur(4px);
`

export const CardTitle = styled.h2`
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
    padding: 10px 0 0;
    letter-spacing: 0.03em;
    line-height: 140%;
    margin: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`
export const CardSubTitle = styled.span`
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.03em;
    color: #707070;
    display: block;
    &::after {
        font-size: 10px;
        letter-spacing: 0.03em;
        color: #707070;
    }
    margin-top: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

export const CardAuthor = styled.span`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #bdbdbd;
    display: block;
`
export const CardTime = styled.span`
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #707070;
    display: block;
    &::after {
        font-size: 10px;
        letter-spacing: 0.03em;
        color: #707070;
    }
`
