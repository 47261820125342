import { RowFlex } from '@/themes/genericStyles'
import styled from 'styled-components'

export const SectionHeading = styled.div`
    ${RowFlex}
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 10px;
`
export const SectionTitle = styled.h2`
    color: #ffffff;
    font-size: 20px;
    font-weight: 600;
    margin: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`

//because you listen to Header

export const BYLHeaderContainer = styled.div`
     {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 16px;
    }
`

export const BYLHeaderImage = styled.img`
     {
        width: 48px;
        height: 48px;
        border-radius: 8px;
    }
`

export const BYLDescriptionAndTitle = styled.div`
     {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 2px;
    }
`

export const BYLDescription = styled.div`
     {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #858585;
    }
`
