const compressedImage = (image, imageMeta) => {
    // console.log('image', image)
    // console.log('image meta', imageMeta)= 'https://images-v2.noiceid.cc/catalog/default/300.png'

    const defaultImg = 'https://images-v2.noiceid.cc/catalog/default/300.png'

    if (typeof image !== 'string') return defaultImg

    if (imageMeta?.['300']) return imageMeta['300']

    if (image) return image

    return defaultImg
}

export default compressedImage
